import React, { useState } from "react";
import { Button, Modal, Select, TextField } from "../../components";
import { PhoneIcon } from "../../assets/icons/PhoneIcon";
import { KeyIcon } from "../../assets/icons/KeyIcon";
import { CityIcon } from "../../assets/icons/CityIcon";
import { changeProfile, flagChange } from "../../services";
import { cityOptions } from "../../constants";
import { AxiosError } from "axios";
import { ProgressButton } from "../../components/progressButton";
import { RefreshIcon } from "../../assets/icons/RefreshIcon";
import classNames from "classnames";

import styles from "./modify.module.scss";

interface IModifyProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Modify: React.FC<IModifyProps> = ({ isOpen, onClose }) => {
  const [isVeryifing, setIsVerifying] = useState(false);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [state, setState] = useState({
    number: "",
    code: "",
    city: "",
    category: "4",
  });

  const [isDataFetched, setIsDataFetched] = useState(false);

  const sendCode = async () => {
    try {
      setErrorMessage("");
      setSuccessMessage("");
      setIsCodeLoading(true);
      await flagChange({
        phone: state.number.replace(/\s/g, ""),
      });
      setIsCodeLoading(false);
      setIsVerifying(true);
    } catch (err) {
      const error = err as AxiosError;

      setErrorMessage(
        error?.response?.status === 404
          ? "ამ ნომერზე აქტიური გამოწერა არ ფიქსირდება"
          : "დაფიქსირდა შეცდომა, სცადეთ მოგვიანებით!"
      );

      setIsCodeLoading(false);
    }
  };

  const verifyCode = async () => {
    try {
      setErrorMessage("");
      setSuccessMessage("");
      setIsCodeLoading(true);
      const res = await changeProfile({
        phone: state.number.replace(/\s/g, ""),
        code: Number(state.code),
        readOnly: true,
      });
      setState((prevState) => ({
        ...prevState,
        city: res.data.center,
        category: res.data.category,
      }));
      setIsDataFetched(true);
      setIsCodeLoading(false);
      setIsVerifying(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      setErrorMessage(
        error?.response?.data.message
          ? error.response.data.message
          : "დაფიქსირდა შეცდომა, სცადეთ მოგვიანებით!"
      );
      setIsCodeLoading(false);
    }
  };

  const changeProfileHandler = async () => {
    try {
      setErrorMessage("");
      setSuccessMessage("");
      setIsCodeLoading(true);
      await changeProfile({
        phone: state.number.replace(/\s/g, ""),
        code: Number(state.code),
        readOnly: false,
        center: state.city,
        category: state.category,
      });
      setIsCodeLoading(false);
      setSuccessMessage("მონაცემები შეიცვალა წარმატებით!");
      setTimeout(onClose, 3000);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      setErrorMessage(error.message);
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = ev.target.value
      .replace(/\D/g, "")
      .replace(/(\d{3})(?=\d)/g, "$1 ");

    setState((prevState) => ({
      ...prevState,
      [ev.target.name]:
        ev.target.name === "number" ? formattedValue : ev.target.value,
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.modifyForm}>
        <p></p>
        <h2>
          {isVeryifing
            ? "შეიყვანეთ მობილურზე მიღებული ხუთნიშნა კოდი"
            : "მონაცემების შეცვლა"}
        </h2>
        {isVeryifing ? (
          <TextField
            value={state.code}
            name="code"
            onChange={onChange}
            placeholder="ხუთნიშნა კოდი"
            icon={<KeyIcon />}
            maxLength={5}
            suffix={
              <ProgressButton
                delay={120}
                onClick={sendCode}
                isLoading={isCodeLoading}
              >
                <div
                  className={classNames(styles.refreshIcon, {
                    [styles.loadingRefresh]: isCodeLoading,
                  })}
                >
                  <RefreshIcon />
                </div>
              </ProgressButton>
            }
          />
        ) : (
          <div>
            <TextField
              value={state.number}
              name="number"
              onChange={onChange}
              placeholder="ტელ. ნომერი"
              prefix="+995"
              readOnly={isDataFetched}
              icon={<PhoneIcon />}
              maxLength={11}
              type="tel"
            />
            {isDataFetched && (
              <Select
                value={state.city}
                onChange={(city) =>
                  setState((prevState) => ({ ...prevState, city }))
                }
                name="city"
                placeholder="აირჩიეთ ქალაქი"
                icon={<CityIcon />}
                className={styles.cityTextField}
                options={cityOptions}
              />
            )}
            {isDataFetched && (
              <div className={styles.landingRadios}>
                <div>
                  <input
                    type="radio"
                    id="4"
                    name="category"
                    value="4"
                    checked={state.category === "4"}
                    onChange={onChange}
                  />
                  <label htmlFor="4">ავტომატიკა</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="3"
                    name="category"
                    value="3"
                    checked={state.category === "3"}
                    onChange={onChange}
                  />
                  <label htmlFor="3">მექანიკა</label>
                </div>
              </div>
            )}
          </div>
        )}
        {isVeryifing ? (
          <Button
            className={styles.cancelButton}
            disabled={!state.code}
            isLoading={isCodeLoading}
            onClick={verifyCode}
          >
            დადასტურება
          </Button>
        ) : (
          <Button
            className={styles.cancelButton}
            onClick={!isDataFetched ? sendCode : changeProfileHandler}
            disabled={!state.number}
            isLoading={isCodeLoading}
          >
            {isDataFetched ? "შეცვლა" : "შემოწმება"}
          </Button>
        )}
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {successMessage && (
          <p className={styles.successMessage}>{successMessage}</p>
        )}
      </div>
    </Modal>
  );
};
