import React from "react";
import styles from "./privacy.module.scss";

export const Privacy: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>კონფიდენციალურობის პოლიტიკა</h1>
      <p>
        gaixsna.ge-ზე თქვენს კონფიდენციალურობას სერიოზულად აღვიქვავთ. ამ გვერდზე
        აგიხსნით თუ როგორ ვაგროვებთ, ვიყენებთ და ვინახავთ თქვენს ინფორმაციას.
        ჩვენი ვებსაიტის გამოყენების შემთხვევაში თქვენ ეთანხმებით ჩვენს
        კონფიდენციალურობის პოლიტიკას.
      </p>
      <h2>ინფორმაცია რომელსაც ვიღებთ</h2>
      <p>
        ერთადერთი პირადი ინფორმაცია რომელსაც თქვენგან ვიღებთ ეს არის მობილურის
        ნომერი. ეს გვჭირდება იმაში, რომ სმს შეტყობინების სახით მოგაწოდოთ
        ინფორმაცია გამოცდის თარიღების გახსნის შესახებ. ჩვენ არანაირ სხვა
        ინფორმაციას არ ვიღებთ თქვენგან, მაგალითად როგორიცაა სახელი, გვარი,
        მისამართი ან მეილი.
      </p>
      <h2>ინფორმაციის გამოყენება</h2>
      <p>
        ჩვენ ვიყენებთ თქვენს ნომერს რათა პირნათლად მოვიხადოთ ჩვენი მოვალეობა და
        საჭირო დროს მოგაწოდოთ შეტყობინებები სმს-ის სახით თქვენს პირად მობილურ
        ნომერზე. თქვენი ნომერი შენახულია მონაცემთა ბაზაში, არ გამოიყენება
        არანაირი მარკეტინგული მხრივ და არ გადაეცემა მესამე პირებს.
      </p>
      <h2>ინფორმაციის დაცულობა</h2>
      <p>
        ჩვენ ვიღებთ ყველა საჭირო ზომას რათა თქვენი ინფორმაცია დაცული იყოს.
        თქვენი მობილურის ნომერი საგულდაგულოდაა დაცული ჩვენს მონაცემთა ბაზაში.
      </p>
      <h2>ინფორმაციის შენახვა</h2>
      <p>
        თქვენი ნომერი შენახული იქნება მანამ, სანამ ეს საჭირო იქნება. თუ თქვენ
        გააუქმებთ გამოწერას, თქვენი ნომერი სისტემიდან მაშინვე წაიშლება.
      </p>
      <p>
        ვებ-საიტზე გამოყენებულია Google Analytics და Hotjar რომელიც აწარმოებს
        სტატისტიკას მომხმარებლების რაოდენობაზე და სხვა მონაცემებზე. მათი
        კონფიდენციალურობის პოლიტიკა შეგიძლიათ იხილოთ მოცემულ ბმულზებზე:{" "}
        <a
          href="https://policies.google.com/privacy?hl=en-GB"
          target="_blank"
          rel="noreferrer"
        >
          Google Analytics
        </a>{" "}
        &#8226;&nbsp;
        <a
          href="https://www.hotjar.com/legal/policies/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Hotjar
        </a>
      </p>
      <h2>ცვლილებები კონფიდენციალურობის პოლიტიკაში</h2>
      <p>
        ჩვენ შეიძლება განვაახლოთ ჩვენი კონფიდენციალურობის პოლიტიკა. ნებისმიერი
        ცვლილება ასახული იქნება ამ დოკუმენტში და თუ დაინტერესებული ხართ
        ცვლილებებით მოგიწოდებთ, რომ პერიოდულად შემოხვიდეთ ამ გვერდზე და
        გადახედოთ.
      </p>
      <h2>დაგვიკავშირდი</h2>
      <p>
        თუ ჩვენთან მიმართებაში რაიმე კითხვები გაქვთ, დაგვიკავშირდით
        ელ-ფოსტაზე:&nbsp;
        <a href="mailto:info@gaixsna.ge">info@gaixsna.ge</a>.
      </p>
    </div>
  );
};
