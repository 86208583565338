import React from "react";
import styles from "./terms.module.scss";

export const Terms: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>წესები და პირობები</h1>
      <p>
        გთხოვთ წაიკითხოთ ჩვენი წესები და პირობები სანამ ჩვენს სერვისს
        გამოიყენებთ. ჩვენი პლატფორმის გამოყენებით თქვენ ავტომატურად ეთანხმებით
        წესებსა და პირობებს.
      </p>
      <h2>სერვისის აღწერა</h2>
      <p>
        ჩვენი ვებსაიტი არის მართვის მოწმობის პრაქტიკული ნაწილის მეორე ეტაპის
        ჯავშნების თარიღების გახსნის შესახებ სმს შეტყობინებების პროვაიდერი
        პლატფორმა. მომხმარებლებს შეუძლიათ გამოიწერონ ჩვენი სერვისი და მიიღონ
        შეტყობინება, როცა მათ სასურველ ქალაქში, მათ სასურველ გადაცემათა კოლოფზე
        გაიხსნება ადგილი.
      </p>
      <h2>მომხმარებელთა მოვალეობა</h2>
      <p>
        1. სარეგისტრაციო ინფორმაცია: მომხმარებლები ვალდებულნი არიან რომ
        წარმოადგინონ სწორი ინფორმაცია რეგისტრაციის დროს, ესენია: ტელეფონის
        ნომერი, სასურველი ქალაქი და მანქანის გადაცემათა კოლოფი.
      </p>
      <p>
        2. გადახდა: მომხმარებელთა ვალდებულნი არიან გადაიხადონ კონკრეტული თანხა
        სერვისის გამოსაწერად. გადახდილი თანხის დაბრუნება არ მოხდება არანაირ
        შემთხვევაში.
      </p>
      <p>
        3. წესები: მომხმარებელთა ვალდებულნი არიან გამოიყენონ ჩვენი ვებსაიტი და
        სერვისი კანონისა და რეგულაციების ფარგებში. ნებისმიერი დარღვევა
        გამოიწვევს მომხმარებელის დაბლოკვას პლატფორმაზე.
      </p>
      <p>
        4. შეტყობინება: შეტყობინების გაგზავნის საჭიროება შეიძლება დღე-ღამის
        ნებისმიერ მონაკვეთში დადგეს. აქედან გამომდინარე სმს შეტყობინება თქვენს
        ნომერზე შეიძლება ნებისმიერ დროს გამოიგზავნოს.
      </p>
      <h2>გამოწერა და გამოწერის გაუქმება</h2>
      <p>
        1. გამოწერა: ჩვენი სერვისის გამოწერით მომხმარებლები თანახმა არიან, რომ
        ჩამოეჭრათ პლატფორმის მოსაკრებელი ავტომატურად, ყოველთვიურად, პირველად გადახდის დღეს (± 24 საათი), 
        საკრედიტო/სადებიტო ბარათიდან.
      </p>
      <p>
        2. გაუქმება: მომხმარებლებს ნებისმიერ დროს შეუძლიათ გააუქმონ გამოწერა,
        თუმცა უნდა ეცადონ, რომ გამოწერა გააუქმონ მორიგი გადახდების ციკლამდე,
        რათა თავიდან აირიდონ ზედმეტი დანახარჯები. გამოწერის გაუქმების
        შემთხვევაში გადახდილი თანხა უკან არ დაგიბრუნდებათ.
      </p>
      <h2>კონფიდენციალურობის პოლიტიკა</h2>
      <p>
        გთხოვთ გადადით{" "}
        <a href="/privacy-policy">კონფიდენციალურობის პოლიტიკის</a> გვერდზე რათა
        გაიაროთ ეს საკითხები.
      </p>
      <h2>ინტელექტუალური ქონება</h2>
      <p>
        ყველანაირი ინტელექტუალური ქონება თუ უფლება რაც გვხვდება პლატფორმაზე
        გვეკუთვნის ჩვენ და მისი გამოყენება ან სხვისთვის გადაცემა აკრძალულია.
      </p>
      <h2>Პასუხისმგებლობის შეზღუდვა</h2>
      <p>
        ჯავშნების სტატუსის გადამოწმების ინტერვალი 1 წუთია, მაგრამ ჩვენ პასუხს არ
        ვაგებთ SMS შეტყობინებების უშუალოდ ტელეფონში მიღების ვადაზე, ეს
        დამოკიდებულია SMS პროვაიდერზე
      </p>
      <p>
        ჩვენ მაქსიმალურად ვციდლობთ სწორი ინფორმაცია მოგაწოდოთ გამოცდების ჯავშნის
        თარიღების გახსნის შესახებ, თუმცა ჩვენ Პასუხისმგებლობას არ ვიღებთ იმ ხარვეზებზე,
        ზიანზე ან დანაკარგზე რაც ჩვენი პლატფორმისა და სერვისის მოხმარების დროს ან შემდეგ
        შეიძლება დადგეს.
      </p>
      <p>
        ვებ-საიტზე გამოყენებულია Google Analytics და Hotjar რომელიც აწარმოებს
        სტატისტიკას მომხმარებლების რაოდენობაზე და სხვა მონაცემებზე. მათი წესები
        და პირობები შეგიძლიათ იხილოთ მოცემულ ბმულზებზე:{" "}
        <a
          href="https://marketingplatform.google.com/about/analytics/terms/us/"
          target="_blank"
          rel="noreferrer"
        >
          Google Analytics
        </a>{" "}
        &#8226;&nbsp;
        <a
          href="https://www.hotjar.com/legal/policies/terms-of-service/"
          target="_blank"
          rel="noreferrer"
        >
          Hotjar
        </a>
      </p>
      <h2>შეწყვეტა</h2>
      <p>
        ჩვენ ვინარჩუნებთ უფლებას, რომ შევწყვიტოთ ან გავაუქმოთ მომხარებლების
        ანგარიშები და წვდომა ჩვენს სერვისთან წინასწარი გაფრთხილებისა და
        მიზეზების გარეშე.
      </p>
      <h2>სამთავრობო კანონი</h2>
      <p>
        ეს წესები და პირობები შედგენილი და დამტკიცებულია საქართველოს
        კონსტიტუციით განსაზღვრული კანონებიდან გამომდინარე.
      </p>
      <h2>ცვლილებები</h2>
      <p>
        ჩვენ ვიტოვებთ უფლებას, რომ განვაახლოთ ეს დოკუმენტში ნებისმიერ დროს
        წინასწარი გაფრთხილების გარეშე. მომხარებლის ვალდებულებაა, რომ ინტერესის
        შემთხვევაში პერიოდულად წვიოს გვერდს და გადახედოს ცვლილებებს.
      </p>
    </div>
  );
};
