import React, { useState } from "react";
import { Button, Modal, TextField } from "../../components";
import { PhoneIcon } from "../../assets/icons/PhoneIcon";
import { KeyIcon } from "../../assets/icons/KeyIcon";
import { cancel, flag } from "../../services";
import { AxiosError } from "axios";
import { ProgressButton } from "../../components/progressButton";
import { RefreshIcon } from "../../assets/icons/RefreshIcon";

import styles from "./cancelation.module.scss";
import classNames from "classnames";
interface ICancelationProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Cancelation: React.FC<ICancelationProps> = ({
  isOpen,
  onClose,
}) => {
  const [isVeryifing, setIsVerifying] = useState(false);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [state, setState] = useState({
    number: "",
    code: "",
  });

  const sendCode = async () => {
    try {
      setIsCodeLoading(true);
      await flag({ phone: state.number.replace(/\s/g, "") });
      setIsCodeLoading(false);
      setIsVerifying(true);
    } catch (err) {
      const error = err as AxiosError;

      setErrorMessage(
        error?.response?.status === 404
          ? "ამ ნომერზე აქტიური გამოწერა არ ფიქსირდება"
          : "დაფიქსირდა შეცდომა, სცადეთ მოგვიანებით!"
      );

      setIsCodeLoading(false);
    }
  };

  const verifyCode = async () => {
    try {
      setIsSubmitLoading(true);
      setSuccessMessage("");
      setErrorMessage("");
      await cancel({
        phone: state.number.replace(/\s/g, ""),
        code: Number(state.code),
      });
      setSuccessMessage("გამოწერა წარმატებით გაუქმდა!");
      setIsSubmitLoading(false);
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      setErrorMessage(error.message ?? "დაფიქსირდა შეცდომა, სცადეთ მოგვიანებით!");
      setIsSubmitLoading(false);
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = ev.target.value
      .replace(/\D/g, "")
      .replace(/(\d{3})(?=\d)/g, "$1 ");

    setState((prevState) => ({
      ...prevState,
      [ev.target.name]:
        ev.target.name === "number" ? formattedValue : ev.target.value,
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.cancelationForm}>
        <h2>
          {isVeryifing
            ? "შეიყვანეთ მობილურზე მიღებული ხუთნიშნა კოდი"
            : "გამოწერის გაუქმება"}
        </h2>
        {isVeryifing ? (
          <TextField
            value={state.code}
            name="code"
            onChange={onChange}
            placeholder="ხუთნიშნა კოდი"
            icon={<KeyIcon />}
            maxLength={5}
            suffix={
              <ProgressButton
                delay={120}
                onClick={sendCode}
                isLoading={isCodeLoading}
              >
                <div
                  className={classNames(styles.refreshIcon, {
                    [styles.loadingRefresh]: isCodeLoading,
                  })}
                >
                  <RefreshIcon />
                </div>
              </ProgressButton>
            }
          />
        ) : (
          <TextField
            value={state.number}
            name="number"
            onChange={onChange}
            placeholder="ტელ. ნომერი"
            prefix="+995"
            icon={<PhoneIcon />}
            maxLength={11}
            type="tel"
          />
        )}
        {isVeryifing ? (
          <Button
            className={styles.cancelButton}
            disabled={!state.code}
            isLoading={isSubmitLoading}
            onClick={verifyCode}
          >
            დადასტურება
          </Button>
        ) : (
          <Button
            className={styles.cancelButton}
            onClick={sendCode}
            disabled={!state.number}
            isLoading={isCodeLoading}
          >
            შემდეგი
          </Button>
        )}
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {successMessage && (
          <p className={styles.successMessage}>{successMessage}</p>
        )}
      </div>
    </Modal>
  );
};
