import React, { useState } from "react";
import styles from "./contact.module.scss";
import { Button, TextField } from "../../components";
import { MessageIcon } from "../../assets/icons/MessageIcon";
import contactImg from "../../assets/images/contact.png";
import { contact } from "../../services";
import { PhoneIcon } from "../../assets/icons/PhoneIcon";

const initialState = {
  phone: "",
  message: "",
};

export const Contact: React.FC = () => {
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [hasError, setHasError] = useState<boolean | null>(null);

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = ev.target.value
      .replace(/\D/g, "")
      .replace(/(\d{3})(?=\d)/g, "$1 ");

    setState((prevState) => ({
      ...prevState,
      [ev.target.name]:
        ev.target.name === "phone" ? formattedValue : ev.target.value,
    }));
  };

  const onSubmit = async () => {
    try {
      setIsSendLoading(true);
      await contact({
        phone: state.phone.replace(/\s/g, ""),
        message: state.message,
      });
      setHasError(false);
      setIsSendLoading(false);
      setState(initialState);
    } catch (err) {
      setIsSendLoading(false);
      setHasError(true);
    }
  };

  return (
    <div className={styles.contactContainer}>
      <div className={styles.contactContent}>
        <div className={styles.contactTop}>
          <h1>დაგვიკავშირდი</h1>
          <span>
            გამოგივიგზავნე კითხვა და ჩვენი დახმარების გუნდი მალევე გიპასუხებს
          </span>
        </div>
        <img src={contactImg} alt="contact" />
        <TextField
          value={state.phone}
          onChange={onChange}
          name="phone"
          placeholder="ტელ. ნომერი"
          icon={<PhoneIcon />}
          prefix="+995"
          maxLength={11}
          type="email"
          className={styles.contactEmail}
        />
        <TextField
          value={state.message}
          onChange={onChange}
          name="message"
          placeholder="მესიჯი"
          icon={<MessageIcon />}
        />
        <Button
          className={styles.submitButton}
          onClick={onSubmit}
          isLoading={isSendLoading}
          disabled={!state.message || !state.phone}
        >
          გაგზავნა
        </Button>
        {hasError === true && <div className={styles.error}>დაფიქსირდა შეცდომა</div>}
        {hasError === false && (
          <div className={styles.success}>შეტყობინება წარმატებით გაიგზავნა</div>
        )}
      </div>
    </div>
  );
};
