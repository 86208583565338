import axios, { AxiosResponse } from 'axios';

interface CancelRequest {
  phone: string;
  code: number;
}

interface ContactRequest {
  phone: string;
  message: string;
}

interface FlagRequest {
  phone: string;
}

interface RegisterRequest {
  phone: string;
  center: string;
  category: string;
  referrer: string;
}

interface FlagChangeRequest {
  phone: string;
}

interface ChangeProfileRequest {
  phone: string;
  code: number;
  readOnly?: boolean;
  center?: string;
  category?: string;
}

// Cancel - POST
export const cancel = (requestData: CancelRequest): Promise<AxiosResponse> => {
  return axios.post('/cancel', requestData);
};

// Contact - POST
export const contact = (requestData: ContactRequest): Promise<AxiosResponse> => {
  return axios.post('/contact', requestData);
};

// Flag - POST
export const flag = (requestData: FlagRequest): Promise<AxiosResponse> => {
  return axios.post('/flag', requestData);
};

// Register - POST
export const register = (requestData: RegisterRequest): Promise<AxiosResponse> => {
  return axios.post('/register', requestData);
};

// Flag Change - POST
export const flagChange = (requestData: FlagChangeRequest): Promise<AxiosResponse> => {
  return axios.post('/flag-change', requestData);
};

// Flag Change - POST
export const changeProfile = (requestData: ChangeProfileRequest): Promise<AxiosResponse> => {
  return axios.post('/change-profile', requestData);
};
