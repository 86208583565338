import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import styles from "./select.module.scss";

interface ISelectProps {
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
  icon: JSX.Element;
  placeholder: string;
  name?: string;
  type?: string;
  options: Array<{ value: string; label: string }>;
}

export const Select: React.FC<ISelectProps> = ({
  value,
  onChange,
  className,
  icon,
  placeholder,
  name,
  type,
  options,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInputValue(
      options.find((option) => option.value === value)?.label || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classNames(styles.selectContainer, className)}
      ref={selectRef}
    >
      <div className={styles.iconContainer}>{icon}</div>
      <input
        value={inputValue}
        onChange={(ev) => setInputValue(ev.target.value)}
        className={styles.selectInput}
        placeholder={placeholder}
        name={name}
        readOnly
        type={type}
        onFocus={() => setShowOptions(true)}
        data-hj-allow
      />
      {showOptions ? (
        <div className={styles.selectOptions}>
          {options.map((option) => {
            const isChosen = options.find(
              (option) => option.label === inputValue
            );

            if (
              inputValue &&
              !option.label
                .toLocaleLowerCase()
                .includes(inputValue.toLowerCase()) &&
              !isChosen
            ) {
              return null;
            }

            return (
              <div
                key={option.value}
                className={classNames(styles.selectOption, {
                  [styles.selectedOption]: option.value === value,
                })}
                onClick={() => {
                  onChange(option.value);
                  setInputValue(option.label);
                  setShowOptions(false);
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
