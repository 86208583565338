import React from "react";
import { useSearchParams } from "react-router-dom";
import success from "../../assets/images/success.png";
import error from "../../assets/images/error.png";

import styles from "./result.module.scss";

interface IResultProps {
  type: "successful_payment" | "unsuccessful_payment";
}

export const Result: React.FC<IResultProps> = ({ type }) => {
  const [params] = useSearchParams();

  const phone = atob(params.get("p")!);

  return (
    <div className={styles.result}>
      <img src={type === "successful_payment" ? success : error} alt={type} />
      <h1>
        {type === "successful_payment"
          ? "თქვენ წარმატებით დარეგისტრირდით gaixsna-ზე"
          : "დაფიქსირდა შეცდომა, სცადეთ მოგვიანებით"}
      </h1>
      <p>
        {type === "successful_payment" && phone
          ? `მართვის მოწმობის პრაქტიკული გამოცდის მეორე ეტაპის ჯავშნების გახსნისთანავე მიიღებთ შეტტობინებას სმს-ის სახით ნომერზე: ${atob(
              phone
            )}`
          : "lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet"}
      </p>
    </div>
  );
};
