import React, { useEffect, useRef, useState } from "react";
import { Button, Select, TextField } from "../../components";
import { Cancelation, Modify } from "../../containers";
import { PhoneIcon } from "../../assets/icons/PhoneIcon";
import { CityIcon } from "../../assets/icons/CityIcon";
import { AxiosError } from "axios";
import { register } from "../../services";
import { useParams } from "react-router-dom";
import { cityOptions } from "../../constants";
import instructions from "../../assets/videos/instruction.mp4";
import styles from "./landing.module.scss";
import { IconPlay } from "../../assets/icons/IconPlay";
import classNames from "classnames";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

const validationSchema = yup.object({
  number: yup
    .string()
    .required("გთხოვთ მიუთითეთ ნომერი")
    .matches(/^[0-9\s]+$/, "ნომერი არასწორადაა მითითებული")
    .length(11, "ნომერი არასწორადაა მითითებული"),
  transmission: yup
    .string()
    .oneOf(["3", "4"], "მიუთითეთ კატეგორია")
    .required("მიუთითეთ კატეგორია"),
  city: yup
    .string()
    .oneOf(
      Object.values(cityOptions).map((v) => v.value),
      "გთხოვთ მიუთითეთ ქალაქი"
    )
    .required("გთხოვთ მიუთითეთ ქალაქი"),
  acceptPayment: yup
    .boolean()
    .oneOf(
      [true],
      "გთხოვთ დაეთანხმოთ კონფიდენციალურობის პოლიტიკას და წესებსა და პირობებს"
    )
    .required(
      "გთხოვთ დაეთანხმოთ კონფიდენციალურობის პოლიტიკას და წესებსა და პირობებს"
    ),
  referrer: yup.string().optional(),
});

export const Landing: React.FC = () => {
  const [modalStatuses, setModalStatuses] = useState({
    calcelation: false,
    modification: false,
  });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      number: "",
      city: "",
      transmission: "4",
      referrer: "",
      acceptPayment: false,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const params = useParams<{ code: string }>();

  const videoRef = useRef<any>();

  useEffect(() => {
    if (params.code) {
      setValue("referrer", params.code);
      localStorage.setItem("referrer", params.code);
    }else{
      const referrer = localStorage.getItem("referrer");
      if(referrer) setValue("referrer", referrer);
    }
  }, [params.code, setValue]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const res = await register({
        phone: data.number.replace(/\s/g, ""),
        center: data.city,
        category: data.transmission,
        referrer: data.referrer.toLowerCase(),
      });
      window.location.href = res.data.redirectUrl;
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;
      setHasError(true);
      setErrorMessage(error.response?.data?.message || "");
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.landingContainer}>
      <div className={styles.landingTop}>
        <h1>"ჯავშნები გაიხსნა?"</h1>
        <p>
          მიიღე SMS შეტყობინება ქალაქის პრაქტიკული გამოცდის (B, B1) ჯავშნების
          გახსნითანავე ყოველთვიურად.
        </p>
        <div className={styles.landingVectorWrapper}>
          {!isVideoPlaying && (
            <div
              className={styles.videoOverlay}
              onClick={() => {
                setIsVideoPlaying(true);
                videoRef.current.play();
              }}
            >
              <h3>როგორ დავრეგისტრირდეთ?</h3>
              <div>
                <IconPlay />
              </div>
            </div>
          )}
          <video
            src={instructions}
            className={styles.landingVector}
            controls={isVideoPlaying}
            ref={videoRef}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.landingForm}>
          <Controller
            name="number"
            control={control}
            render={({ field }) => (
              <div>
                <TextField
                  value={field.value}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value
                        .replace(/\D/g, "")
                        .replace(/(\d{3})(?=\d)/g, "$1 ")
                    )
                  }
                  onBlur={field.onBlur}
                  name="number"
                  placeholder="ტელ. ნომერი"
                  icon={<PhoneIcon />}
                  maxLength={11}
                  prefix="+995"
                />
                {errors.number && (
                  <p className={styles.errorMessage}>
                    {errors.number?.message}
                  </p>
                )}
              </div>
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <div>
                <Select
                  value={field.value}
                  onChange={(city) => field.onChange(city)}
                  name="city"
                  placeholder=" აირჩიეთ ქალაქი"
                  icon={<CityIcon />}
                  className={styles.cityTextField}
                  options={cityOptions}
                />
                {errors.city && (
                  <p className={styles.errorMessage}>{errors.city?.message}</p>
                )}
              </div>
            )}
          />
          <div className={styles.landingRadios}>
            <div>
              <Controller
                render={({ field }) => (
                  <React.Fragment>
                    <input
                      type="radio"
                      id="automatic"
                      name="transmission"
                      value="4"
                      defaultChecked
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    <label htmlFor="automatic">ავტომატიკა</label>
                  </React.Fragment>
                )}
                control={control}
                name="transmission"
              />
            </div>
            <div>
              <Controller
                name="transmission"
                control={control}
                render={({ field }) => (
                  <React.Fragment>
                    <input
                      type="radio"
                      id="manual"
                      name="transmission"
                      value="3"
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    <label htmlFor="manual">მექანიკა</label>
                  </React.Fragment>
                )}
              />
            </div>
            {errors.transmission && (
              <p className={styles.errorMessage}>
                {errors.transmission?.message}
              </p>
            )}
          </div>
          <div className={styles.acceptPayment}>
            <Controller
              name="acceptPayment"
              control={control}
              render={({ field }) => (
                <React.Fragment>
                  <input
                    id="acceptPayment"
                    type="checkbox"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    name="acceptPayment"
                    className={classNames(styles.checkboxWrapper, {
                      [styles.errorCheckbox]: errors.acceptPayment,
                    })}
                  />
                  <label htmlFor="acceptPayment">
                    &nbsp;ვეთანხმები კონფიდენციალურობის პოლიტიკას და წესებსა და
                    პირობებს
                  </label>
                  {errors.acceptPayment && (
                    <p
                      style={{ marginTop: "10px" }}
                      className={styles.errorMessage}
                    >
                      {errors.acceptPayment?.message}
                    </p>
                  )}
                </React.Fragment>
              )}
            />
          </div>
          <Button
            className={styles.submitButton}
            type="submit"
            isLoading={isLoading}
          >
            რეგისტრაცია (3.99₾/თვე)
          </Button>

          <p className={styles.warningText}>
            <span>შენიშვნა:</span> გადახდა შესაძლებელია ნებისმიერი ბანკის
            ბარათით
          </p>

          {hasError && (
            <p className={styles.errorMessage}>
              {errorMessage
                ? errorMessage
                : "დაფიქსირდა შეცდომა, სცადეთ მოგვიანებით."}
            </p>
          )}
          <div className={styles.landingBottom}>
            <div className={styles.cancel}>
              <span
                onClick={() =>
                  setModalStatuses((prevState) => ({
                    ...prevState,
                    calcelation: true,
                  }))
                }
              >
                გამოწერის გაუქმება
              </span>
            </div>
            <div className={styles.cancel}>
              <span
                onClick={() =>
                  setModalStatuses((prevState) => ({
                    ...prevState,
                    modification: true,
                  }))
                }
              >
                მონაცემების შეცვლა
              </span>
            </div>
          </div>
          {modalStatuses.calcelation && (
            <Cancelation
              isOpen={modalStatuses.calcelation}
              onClose={() =>
                setModalStatuses((prevState) => ({
                  ...prevState,
                  calcelation: false,
                }))
              }
            />
          )}
          {modalStatuses.modification && (
            <Modify
              isOpen={modalStatuses.modification}
              onClose={() =>
                setModalStatuses((prevState) => ({
                  ...prevState,
                  modification: false,
                }))
              }
            />
          )}
        </form>
      </div>
    </div>
  );
};
