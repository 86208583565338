import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Landing, Result, About, Contact, Privacy, Terms } from "./screens";
import { Footer } from "./components/footer";
import axios from "axios";
import "./App.css";

axios.defaults.baseURL =
  "https://facez7a3xe.execute-api.eu-north-1.amazonaws.com/prod/api/v1";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:code" element={<Landing />} />
        <Route path="/" element={<Landing />} />
        <Route path="/success" element={<Result type="successful_payment" />} />
        <Route path="/error" element={<Result type="unsuccessful_payment" />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<Terms />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
