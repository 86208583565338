import React from "react";
import styles from "./about.module.scss";

export const About: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>ჩვენს შესახებ</h1>
      <p>
        მოგესალმებით <span className={styles.websiteName}>gaixsna-ზე</span>!
      </p>
      <p>
        <span className={styles.websiteName}>gaixsna-ზე</span> ჩვენ ვზრუნავთ
        მართვის მოწმობის პრაქტიკული ნაწილის მეორე ეტაპის დაჯავშნის გამარტივებაზე.
        ჩვენ გვესმის, რომ დღეების განმავლობაში ჯავშნების სტატუსის შემოწმება დამღლელია და დიდი დრო სჭირდება, ამიტომაც
        შევქმენით პლატფორმა, რათა გაგიმარტივოთ ეს პროცესი და ადგილების
        გახსნისთანავე მიიღოთ SMS შეტყობინება.
      </p>
      <p>
        ჩვენი მისიაა რომ მოგაწოდოთ საჭირო ინფორმაცია საჭირო დროს რათა თქვენთვის
        ხელმისაწვდომი იყოს ყველაზე მეტი დრო და დაჯავშნოთ ის, რომელიც ყველაზე
        მეტად ერგება თქვენს მოთხოვნებს. გამუდმებით ადგილების შემოწმების ნაცვლად
        შეგიძლიათ უბრალოდ მოემზადოთ გამოცდისთვის.
      </p>
      <p>
        მთავარი ფუნქციები და სარგებელი{" "}
        <span className={styles.websiteName}>gaixsna-ზე:</span>{" "}
      </p>
      <ol>
        <li>
          თქვენ ქალაქის გამოცდის ჯავშნების გახსნისთანავე იღებთ SMS შეტყობინებას.
          ეს კი გაძლევთ საშუალებას მალევე შეხვიდეთ დასაჯავშნად და აირჩიოთ ის
          დრო, რომელიც თქვენ გსურთ. gaixsna-ს საშუალებით დაზოგავთ ქალაქის
          ჯავშნების სტატუსის შესამოწმებლად დახარჯულ დროს.
        </li>
        <li>
          თქვენ აპლიკაციაში რეგისტრაციისთანავე უთითებთ ქალაქს რომლითაც ხართ
          დაინტერესებული და ასევე იმ გადაცემათა კოლოფს, თუ რომელზეც აპირებთ
          გამოცდის ჩაბარებას. აქედან გამომდინარე თქვენ იღებთ შეტყობინებას იმ
          თარიღებზე რომელიც თქვენთვის შესაბამისია.
        </li>
        <li>
          სანდოობა და დაცულობა. თქვენი ინფორმაცია ჩვენთან საგულდაგულოდაა დაცული.
          ამასთანავე ჩვენი ტექნიკური ჯგუფი მუდმივად მუშაობს პლატფორმის
          გამართულად მუშაობაზე.
        </li>
      </ol>
      <p>
        მიუხედავად იმისა პირველად იღებთ მართვის მოწმობას თუ უბრალოდ ახალ
        კატეგორიას ამატებთ <span className={styles.websiteName}>gaixsna.ge</span>{" "}
        მზადაა დაგეხმაროთ მართვის მოწმობის აღების პროცესის გამარტივებაში.
      </p>
    </div>
  );
};
