export const cityOptions = [
  { label: "ქუთაისი (Kutaisi)", value: "2" },
  { label: "ბათუმი (Batumi)", value: "3" },
  { label: "თელავი (Telavi)", value: "4" },
  { label: "ახალციხე (Akhaltsikhe)", value: "5" },
  { label: "ზუგდიდი (Zugdidi)", value: "6" },
  { label: "გორი (Gori)", value: "7" },
  { label: "ფოთი (Poti)", value: "8" },
  { label: "ოზურგეთი (Ozurgeti)", value: "9" },
  { label: "საჩხერე (Sachkhere)", value: "10" },
  { label: "რუსთავი (Rustavi)", value: "15" },
];
