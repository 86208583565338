import React, { useEffect, useState } from "react";

import styles from "./progressButton.module.scss";

interface IProps {
  delay: number;
  onClick: () => void;
  children: JSX.Element;
  isLoading?: boolean;
}

export const ProgressButton: React.FC<IProps> = ({
  delay,
  onClick,
  children,
  isLoading,
}) => {
  const [progress, setProgress] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress >= 100) {
        clearInterval(interval);
        return;
      }
      setProgress((prev) => prev + 100 / delay);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, refreshInterval]);

  const onClickHandler = async () => {
    await onClick();
    setProgress(0);
    setRefreshInterval(!refreshInterval);
  };

  return (
    <button
      onClick={onClickHandler}
      disabled={progress < 100 || isLoading}
      className={styles.progressButton}
    >
      {children}
      {!isLoading && (
        <div
          className={styles.progressOverlay}
          style={{ width: `${progress}%` }}
        />
      )}
    </button>
  );
};
