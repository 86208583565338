import React from "react";
import classNames from "classnames";
import { LoaderIcon } from "../../assets/icons/LoaderIcon";

import styles from "./button.module.scss";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
  isLoading = false,
  disabled = false,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(styles.button, props.className)}
      disabled={isLoading || disabled}
    >
      {isLoading ? <LoaderIcon /> : null}
      {props.children}
    </button>
  );
};
