import React from "react";

import styles from "./textfield.module.scss";
import classNames from "classnames";

interface ITextFieldProps {
  value?: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  icon: JSX.Element;
  placeholder: string;
  name?: string;
  type?: string;
  maxLength?: number;
  prefix?: string;
  suffix?: JSX.Element;
  readOnly?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
}

export const TextField: React.FC<ITextFieldProps> = ({
  value,
  onChange,
  className,
  icon,
  placeholder,
  name,
  type,
  maxLength,
  suffix,
  prefix,
  readOnly,
  onBlur,
  ...props
}) => {
  return (
    <div className={classNames(styles.textfieldContainer, className)}>
      <div className={styles.iconContainer}>{icon}</div>
      {prefix ? (
        <div className={styles.prefix}>
          <span>{prefix}</span>
        </div>
      ) : null}
      <input
        onBlur={onBlur}
        value={value}
        onChange={onChange}
        className={styles.textfieldInput}
        placeholder={placeholder}
        name={name}
        type={type}
        readOnly={readOnly}
        maxLength={maxLength}
        data-hj-allow
        {...props}
      />
      {suffix && suffix}
    </div>
  );
};
